import React, { useEffect, useState } from "react";
import {
  IonApp,
  IonButton,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import { useSelector } from "react-redux";
import Search from "components/mobile-components/Common/Search";
import SelectBrands from "components/mobile-components/SelectBrands";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Brand from "services/Brand";
import { Link } from "react-router-dom";

export default function SelectBrandsPage() {
  const selectedBrandList = useSelector((state) => state.user.selectedBrands);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [brandList, setBrandList] = useState([]);
  const [pageCount, setPageCount] = useState(2);
  const [searchQ, setSearchQ] = useState("");
  const [brandListLoader, setBrandLoader] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);

  const getAllBrandList = async () => {
    setReachedEnd(false);
    setHasMoreData(true);
    setPageCount(2);
    if (searchQ) {
      let response = await Brand.getAllBrandListSearch(searchQ);
      if (response.status === 200) {
        setBrandLoader(false);
        setBrandList(response.results);
      } else {
        setBrandLoader(false);
      }
    } else {
      let response = await Brand.getAllBrandList();
      if (response.status === 200) {
        setBrandList(response.results);
        setBrandLoader(false);
      } else {
        setBrandLoader(false);
      }
    }
  };

  let lodeMoreData = async () => {
    if (hasMoreData) {
      if (searchQ) {
        const response = await Brand.getAllBrandListSearch(searchQ, pageCount);
        if (response.status === 200) {
          setPageCount((prev) => prev + 1);
          setBrandList([...brandList, ...response.results]);
        } else {
          setHasMoreData(false);
        }
      } else {
        const response = await Brand.getAllBrandList(pageCount);
        if (response.status === 200) {
          setPageCount((prev) => prev + 1);
          setBrandList([...brandList, ...response.results]);
        } else {
          setHasMoreData(false);
        }
      }
    }
  };

  let singleBrandSearch = async () => {
    let response = await Brand.getAllBrandListSearch(searchQ);
    if (response.status === 200) {
      setBrandList(response.results);
    }
  };

  useEffect(() => {
    if (searchQ === "") {
      getAllBrandList();
    }
  }, [searchQ]);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle
            title={ekkLanguage.businessProfile.verifyBusinessMenu}
          />
          <section className="pt-20 px-4 ">
            <Search
              placeholder={"Search Brand by Name"}
              search={searchQ}
              setSearch={setSearchQ}
              singleSearch={singleBrandSearch}
              setHasMoreData={setHasMoreData}
              setReachedEnd={setReachedEnd}
              filter={false}
            />
            {brandListLoader ? (
              <IonLoading
                isOpen={true}
                message={ekkLanguage.orderList.loader}
              ></IonLoading>
            ) : (
              <SelectBrands
                brandList={brandList}
                ekkLanguage={ekkLanguage}
                selectedBrandList={selectedBrandList}
              ></SelectBrands>
            )}
            <div
              className="bg-white fixed bottom-0 left-0 w-full p-2 pb-2 z-10"
              style={{ boxShadow: "0px -2px 15px rgba(0, 0, 0, 0.1)" }}
            >
              <div>
                <Link to={`/brand-agreement-verification`}>
                  <IonButton
                    expand="block"
                    className="bg-[#0c921c] rounded-xl h-12 font-sans font-bold shadow-none text-20"
                  >
                    {ekkLanguage.selectProduct.btnLabel}
                  </IonButton>
                </Link>
              </div>
            </div>
          </section>
          <div>
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                if (reachedEnd) {
                  ev.target.complete();
                  return;
                }
                lodeMoreData();
                setTimeout(() => {
                  ev.target.complete();
                  if (!hasMoreData) {
                    setReachedEnd(true);
                  }
                }, 1000);
              }}
            >
              <IonInfiniteScrollContent
                loadingText={ekkLanguage.orderList.loader}
                loadingSpinner={hasMoreData ? "bubbles" : null}
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
