import { IonItem } from "@ionic/react";

import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { setBrandDetails } from "redux/container/userSlice";
import { Modal } from "react-responsive-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { ClipLoader } from "react-spinners";
import Brand from "services/Brand";

export default function BrandDetailsBottomSheet({ brandInfo }) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.brandDetails);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [cancelLoader, setCancelLoader] = useState(false);
	const [cancelImportLoader, setCancelImportLoader] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);

	const [isOpenImport, setIsOpenImport] = useState(false);
	const onOpenImportModal = () => setIsOpenImport(true);
	const onCloseImportModal = () => setIsOpenImport(false);

	const importBrand = async () => {
		let query = {
			brand_id: brandInfo.id,
		};
		let response = await Brand.importBrand(query);
		if (response.success) {
			dispatch(setBrandDetails(false));
			onCloseImportModal();
		}
	};
	const deleteHandler = async () => {
		setCancelLoader(true);
		let response = await Brand.brandDelete(brandInfo.id);
		if (response.success) {
			setCancelLoader(false);
			dispatch(setBrandDetails(false));
			onCloseModal();
			navigate("/brand-list");
		} else {
			setCancelLoader(false);
		}
	};

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[260, 260, 100, 0]}
				onClose={() => dispatch(setBrandDetails(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						{!brandInfo?.is_own ? (
							<IonItem button onClick={onOpenImportModal} lines='none'>
								<div className='flex items-center'>
									<span className='py-[5px] pr-4'>
										<img
											className='h-10 w-10'
											src='/assets/images/Icon/import.png'
											alt='user'
										/>
									</span>
									<p className='text-16 font-bold text-black-1000 pl-2'>
										{ekkLanguage.brandDetails.import}
									</p>
								</div>
							</IonItem>
						) : (
							<>
								{brandInfo?.role !== "Imported" && (
									<IonItem
										button
										onClick={() =>
											navigate(`/update-brand/${brandInfo.id}`)
										}
										lines='none'>
										<div className='flex items-center'>
											<span className='py-[5px] pr-4'>
												<img
													className='h-10 w-10'
													src='/assets/images/Icon/edit.png'
													alt='user'
												/>
											</span>
											<p className='text-16 font-bold text-black-1000 pl-2'>
												{ekkLanguage.brandDetails.edit}
											</p>
										</div>
									</IonItem>
								)}
								<IonItem button onClick={onOpenModal} lines='none'>
									<div className='flex items-center'>
										<span className='py-[5px] pr-4'>
											<img
												className='h-10 w-10'
												src='/assets/images/Icon/delete.png'
												alt='user'
											/>
										</span>
										<p className='text-16 font-bold text-black-1000 pl-2'>
											{ekkLanguage.brandDetails.delete}
										</p>
									</div>
								</IonItem>{" "}
							</>
						)}
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop onClick={() => dispatch(setBrandDetails(false))} />
			</Sheet>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.brandDetails.deleteConformationAsk}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={!cancelLoader && deleteHandler}>
							{cancelLoader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				open={isOpenImport}
				onClose={onCloseImportModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createNewBrand.importBrandConformations}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseImportModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-[#0c921c] text-white'
							onClick={!cancelImportLoader && importBrand}>
							{cancelImportLoader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
