import HeartOutline from "assets/images/icon/svg/HeartOutline";
import LeftArrow from "assets/images/icon/svg/LeftArrow";
import Share from "assets/images/icon/svg/Share";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function BackButton({ sticky }) {
	const navigate = useNavigate();
	return (
		<div className={`header-wrapper ${sticky}`}>
			<div
				className='short-nav absolute left-4 top-7 bg-E6E6E6 rounded-xl z-10 '
				style={{
					height: 40,
					width: 40,
					boxShadow: "0 5px 10px rgba(0,0,0,.1)",
				}}
				onClick={() => navigate(-1)}>
				<div className=' h-full flex items-center justify-center'>
					<LeftArrow />
				</div>
			</div>
			<div className='long-nav w-full bg-[#0c921c]'>
				<div
					className='flex justify-between items-center p-4 '
					style={{ boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)" }}>
					<div
						className='left-4 top-7 bg-E6E6E6 rounded-xl z-10 backIcon'
						style={{
							height: 40,
							width: 40,
						}}
						onClick={() => navigate(-1)}>
						<div className='h-full flex items-center justify-center'>
							<LeftArrow color={sticky ? "white" : "white"} />
						</div>
					</div>
					<div>
						<ul className='flex items-center'>
							<li className='px-2'>
								<div>
									<HeartOutline />
								</div>
							</li>
							<li className='px-2'>
								<div>
									<Share />
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
