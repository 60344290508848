import { IonButton, IonCol, IonRow } from "@ionic/react";
import React, { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../Common/TextInput";
import Select from "react-select";
import { productCreatedCountry } from "data/country";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { ClipLoader } from "react-spinners";
import { getProductBrand, getUser } from "config/user";
import Add from "assets/images/icon/svg/Add";
import Cross from "assets/images/icon/svg/Cross";
import TextInputWithIcon from "../Common/TextInputWithIcon";
import SelectWithIcon from "../Common/SelectWithIcon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Product from "services/Product";
import { useEffect } from "react";
import ImageUploadWithCamera from "../Common/ImageUploadWithCamera";
import {
	productOfferCreate,
	setEmptyProductOffer,
} from "redux/container/productSlice";
import User from "services/User";
import { setMyProduct } from "redux/container/userSlice";
import ProductCard from "./ProductCard";
import { toast } from "react-toastify";

export default function CreateOffer() {
	let user = getUser();
	let product = useSelector((state) => state.product.productOffer);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [productName, setProductName] = useState(product?.productName);
	const [packQty, setPackQty] = useState(product?.packQty);
	const [productDescription, setProductDescription] = useState(
		product?.description
	);
	const [uploadImage, setUploadImage] = useState(product?.images);
	const [sellingPrice, setSellingPrice] = useState(product?.sellingPrice);
	const [customerPrice, setCustomerPrice] = useState(product?.customerPrice);
	const [taxVat, setTaxVat] = useState(product?.taxVat);
	const [moq, setMoq] = useState(product?.moq);
	const [country, setCountry] = useState(user.country);
	const [weight, setWeight] = useState(product?.weight);
	const [grossWeight, setGrossWeight] = useState(product?.grossWeight);
	const [grossWeightUnit, setGrossWeightUnit] = useState(
		product?.grossWeightUnit
	);
	const [measurement, setMeasurement] = useState(product?.measurement);
	const [packagingType, setPackagingType] = useState(product?.packagingType);
	const [width, setWidth] = useState(product?.width);
	const [height, setHeight] = useState(product?.height);
	const [length, setLength] = useState(product?.length);
	const [initInventory, setInitInventory] = useState(product?.initInventory);
	const [manufacturer, setManufacturer] = useState(product?.manufacturer);
	const [importer, setImporter] = useState(product?.importer);
	const [distributer, setDistributer] = useState(product?.distributer);
	const [sku, setSku] = useState(product?.sku);
	const [purchasePrice, setPurchasePrice] = useState(product?.purchasePrice);
	const [measurementUnit, setMeasurementUnit] = useState(
		product?.measurementUnit
	);
	const [searchName, setSearchName] = useState(product?.searchName);
	const navigate = useNavigate();
	const selectedBrand = getProductBrand();
	const [department, setDepartment] = useState(product?.department);

	const [loader, setLoader] = useState(false);

	const [isOpen, setIsOpen] = useState(false);
	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);

	const [isOpenSaveProduct, setIsOpenSaveProduct] = useState(false);
	const onOpenModalSaveProduct = () => setIsOpenSaveProduct(true);
	const onCloseModalSaveProduct = () => setIsOpenSaveProduct(false);

	const [isOpenPrice, setIsOpenPrice] = useState(false);
	const onOpenPriceModal = () => setIsOpenPrice(true);
	const onClosePriceModal = () => setIsOpenPrice(false);

	const [isOpenSize, setIsOpenSize] = useState(false);
	const onOpenSizeModal = () => setIsOpenSize(true);
	const onCloseSizeModal = () => setIsOpenSize(false);

	const [selectProductModal, setSelectProductModal] = useState(false);
	const onOpenSelectModal = () => setSelectProductModal(true);
	const onCloseSelectModal = () => setSelectProductModal(false);

	const [isOpenManufacturer, setIsOpenManufacturer] = useState(false);
	const onOpenManufacturerModal = () => setIsOpenManufacturer(true);
	const onCloseManufacturerModal = () => setIsOpenManufacturer(false);

	const [isOpenInventory, setIsOpenInventory] = useState(false);
	const onOpenInventoryModal = () => setIsOpenInventory(true);
	const onCloseInventoryModal = () => setIsOpenInventory(false);
	const dispatch = useDispatch();
	const [isCameraMood, setIsCameraMood] = useState(false);
	const [sellingPriceEmpty, setSellingPriceEmpty] = useState("");
	const [imageURL, setImageURL] = useState("");
	const [imageLoader, setImageLoader] = useState(false);
	const [publishDisabled, setPublishDisabled] = useState(true);
	const [psWarnings, setPSWarnings] = useState("");

	var countryList = Object.values(productCreatedCountry).map((val) => ({
		countryCode: val.code,
		country: val.name,
		value: val.code,
		flag: val.flag,
		label: (
			<div className='flex items-center'>
				<img
					src={val.flag}
					className='h-auto w-12 rounded-[5px]'
					alt='flag'></img>{" "}
				<p className='text-16 pl-2'>{val.name}</p>
			</div>
		),
	}));
	let userCountry = countryList.find(
		(country) => country.country === user.country
	);

	let selectedCurrency = Object.values(productCreatedCountry).find(
		(el) => el.name === country
	);

	const handleSelectCountry = (e) => {
		// setCountryCode(e.countryCode);
		setCountry(e.country);
	};
	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
			// height: "calc(100% - 60px)",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	const customModalStyles2 = {
		content: {
			...customModalStyles.content,
			height: "auto",
			maxHeight: "calc(100% - 60px)",
		},
		overlay: {
			...customModalStyles.overlay,
		},
	};
	let customStyleSelect = {
		control: (baseStyles, state) => ({
			...baseStyles,
			borderColor: "rgba(0,0,0,0.5)",
			background: "#F8F8F8",
			height: "50px",
			borderRadius: "5px",
		}),
	};

	// all options
	const measurementOptions = [
		{ value: "kg", label: "Kilogram" },
		{ value: "gm", label: "Gram" },
		{ value: "ml", label: "Mililitre" },
		{ value: "lb", label: "Pound" },
		{ value: "l", label: "Liter" },
		{ value: "m", label: "Meter" },
		{ value: "cm", label: "Centimeter" },
		{ value: "mm", label: "Millimeter" },
		{ value: "in", label: "Inches" },
		{ value: "ft", label: "Foot" },
		{ value: "pc", label: "Piece" },
	];
	const packingOptions = [
		{ value: "Box", label: "Box" },
		{ value: "Bag", label: "Bag" },
		{ value: "Bottle", label: "Bottle" },
		{ value: "Can", label: "Can" },
		{ value: "Wrapper", label: "Wrapper" },
		{ value: "Cup", label: "Cup" },
		{ value: "Bowl", label: "Bowl" },
		{ value: "Packet", label: "Packet" },
		{ value: "Carton", label: "Carton" },
		{ value: "Roll", label: "Roll" },
	];
	const unitOptions = [
		{ value: "cm", label: "Centimeter" },
		{ value: "in", label: "Inches" },
		{ value: "m", label: "Meter" },
	];
	const grossUnitOptions = [
		{ value: "kg", label: "Kilogram" },
		{ value: "gm", label: "Gram" },
		{ value: "lb", label: "Pound" },
	];
	const OfferStartDate = forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-white p-2 block rounded-[5px] px-4 py-[14px] mb-5`}
			// onClick={onOpenSizeModal}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					<img src='assets/images/calendar.png' alt='icon' />
					<p className={`text-16 "text-black-500 font-medium ml-2`}>
						{product?.manufactureDate ? (
							moment(product?.manufactureDate).format("DD-MM-YYYY")
						) : (
							<>{ekkLanguage.createOffer.offerStartDate}</>
						)}
					</p>
				</div>
				<div>
					<Add color='#F94B41' />
				</div>
			</div>
		</div>
	));

	const OfferEndDate = forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-white p-2 block rounded-[5px] px-4 py-[14px] mb-5`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					<img src='assets/images/calendar2.png' alt='icon' />
					<p className={`text-16 "text-black-500 font-medium ml-2`}>
						{product?.expireDate ? (
							moment(product?.expireDate).format("DD-MM-YYYY")
						) : (
							<>{ekkLanguage.createOffer.offerEndDate}</>
						)}
					</p>
				</div>
				<div>
					<Add color='#F94B41' />
				</div>
			</div>
		</div>
	));

	useEffect(() => {
		dispatch(
			productOfferCreate({
				...product,
				productName,
				images: uploadImage,
				packQty,
				description: productDescription,
				sellingPrice,
				customerPrice,
				taxVat,
				moq,
				weight,
				measurement,
				packagingType,
				width,
				height,
				length,
				measurementUnit,
				initInventory,
				sku,
				country,
				manufacturer,
				importer,
				distributer,
				purchasePrice,
				searchName,
				grossWeight,
				grossWeightUnit,
			})
		);
	}, [
		packQty,
		productDescription,
		sellingPrice,
		customerPrice,
		taxVat,
		moq,
		weight,
		measurement,
		packagingType,
		width,
		height,
		length,
		measurementUnit,
		initInventory,
		sku,
		country,
		manufacturer,
		importer,
		distributer,
		purchasePrice,
		uploadImage,
		productName,
		searchName,
		grossWeight,
		grossWeightUnit,
	]);

	let createProductPublishedHandler = async (ps) => {
		setLoader(true);
		let selectedItems = product?.productItems?.map((el) => {
			return { product: el.product_id, quantity: el.orderQuantity };
		});
		let productInfo = {
			offers: {
				title: productName,
				description: productDescription,
				// barcode: "",
				sku,
				offer_price: sellingPrice,
				consumer_price: customerPrice,
				purchase_price: purchasePrice,
				type: "offer",
				weight_unit: measurement?.value,
				weight,
				dimension_unit: measurementUnit?.value,
				height,
				width,
				length,
				pack_type: packagingType?.value,
				publish_status: ps,
				quantity_in_pack: product?.packQty,
				master_product: product?.productItem?.product_id,
				offer_end: product.expireDate,
				offer_start: product.manufactureDate,
				images: imageURL ? imageURL : [],
				tax: taxVat,
				minimum_order_quantity: moq,
				inventory_quantity: initInventory,
				eng_name: searchName,
				gross_weight: product.grossWeight,
				gross_weight_unit: grossWeightUnit?.value,
			},
			offer_iteams: selectedItems,
		};

		let formData = new FormData();
		if (uploadImage?.length) {
			if (Array.isArray(uploadImage)) {
				formData.append("key", uploadImage[0]);
				let response = await User.toBucketImage(formData);
				if (response.status === 201) {
					setImageLoader(false);
					setImageURL(response?.results);
					let productData = {
						offers: {
							...productInfo.offers,
							images: response?.results,
						},
						offer_iteams: selectedItems,
					};

					let res = await Product.createOffer(productData);
					if (res.status === 201) {
						setLoader(false);
						toast.success(ekkLanguage.createOffer.offerCreated);
						dispatch(setMyProduct(false));
						localStorage.removeItem("pb");
						onCloseModal();
						navigate("/product-list");
						dispatch(setEmptyProductOffer());
					}
				} else {
					setImageLoader(false);
					setLoader(false);
				}
			} else {
				function DataURIToBlob(dataURI) {
					const splitDataURI = dataURI.split(",");
					const byteString =
						splitDataURI[0].indexOf("base64") >= 0
							? atob(splitDataURI[1])
							: decodeURI(splitDataURI[1]);
					const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

					const ia = new Uint8Array(byteString.length);
					for (let i = 0; i < byteString.length; i++)
						ia[i] = byteString.charCodeAt(i);
					return new Blob([ia], { type: mimeString });
				}

				const cameraFile = DataURIToBlob(uploadImage);
				const cameraData = new FormData();
				cameraData.append("key", cameraFile, "image.jpg");
				let cameraResponse = await User.toBucketImage(cameraData);

				let productData = {
					offers: {
						...productInfo.offers,
						images: cameraResponse?.results,
					},
					offer_iteams: selectedItems,
				};
				if (cameraResponse.status === 201) {
					setImageURL(cameraResponse?.results);
					setImageLoader(false);
					let response = await Product.createOffer(productData);
					if (response.status === 201) {
						setLoader(false);
						toast.success(ekkLanguage.createOffer.offerCreated);
						dispatch(setMyProduct(false));
						localStorage.removeItem("pb");
						onCloseModal();
						navigate("/product-list");
						dispatch(setEmptyProductOffer());
					} else {
						setLoader(false);
					}
				} else {
					setImageLoader(false);
				}
			}
		} else {
			let response = await Product.createOffer(productInfo);
			if (response.status === 201) {
				setLoader(false);
				toast.success(ekkLanguage.createOffer.offerCreated);
				dispatch(setMyProduct(false));
				localStorage.removeItem("pb");
				onCloseModal();
				navigate("/product-list");
				dispatch(setEmptyProductOffer());
			} else {
				setLoader(false);
			}
		}
	};

	useEffect(() => {
		if (sellingPrice && initInventory && productName) {
			setPublishDisabled(false);
		} else {
			setPublishDisabled(true);
		}
	}, [sellingPrice, initInventory, productName]);

	return (
		<div>
			<div className='pt-4 px-4'>
				<h6 className='text-16 text-black-333'>
					{ekkLanguage.createOffer.offerWarning}
				</h6>
			</div>

			<div className='pt-5 px-4'>
				<div
					className={`bg-white p-2 block rounded-[5px] px-4 py-[14px] mb-5`}
					onClick={() => navigate("/select-product/offer")}>
					<div className='flex justify-between items-center'>
						<div className='flex items-center'>
							<img
								className='w-[30px]'
								src='assets/images/package-color-icon.png'
								alt='badge'
							/>
							<p className={`text-16 "text-black-500 font-medium ml-2`}>
								{product?.department?.title ??
									ekkLanguage.createPack.selectProduct}
							</p>
						</div>

						<div>
							<Add
								color={
									product.productItems?.length ? "#00b224" : "#F94B41"
								}
							/>
						</div>
					</div>
				</div>
				{product.productItems?.length > 0 && <ProductCard />}

				<ImageUploadWithCamera
					isCameraMood={isCameraMood}
					setIsCameraMood={setIsCameraMood}
					uploadImage={uploadImage}
					setUploadImage={setUploadImage}
				/>

				<div className='pt-5'>
					<TextInput
						type='text'
						title={ekkLanguage.createOffer.offerName}
						inputText={productName}
						setInputText={setProductName}
						placeholder={ekkLanguage.createOffer.offerNamePlaceHolder}
						required={true}
					/>
					<TextInput
						textarea={true}
						title={ekkLanguage.createProduct.description}
						inputText={productDescription}
						setInputText={setProductDescription}
						placeholder={ekkLanguage.createProduct.descriptionPlaceHolder}
					/>
				</div>

				<div>
					<div
						className={`bg-white p-2 block rounded-[5px] px-4 py-[14px] mb-5`}
						onClick={onOpenPriceModal}>
						<div className='flex justify-between items-center'>
							<div className='flex items-center'>
								<img src='assets/images/dollar.png' alt='icon' />
								<p
									className={`text-16 "text-black-500 font-medium ml-2`}>
									{product?.sellingPrice ?? (
										<>{ekkLanguage.createProduct.setPrice} </>
									)}
								</p>
							</div>
							<div>
								<Add color='#F94B41' />
							</div>
						</div>
					</div>
					<div
						className={`bg-white p-2 block rounded-[5px] px-4 py-[14px] mb-5`}
						onClick={onOpenSizeModal}>
						<div className='flex justify-between items-center'>
							<div className='flex items-center'>
								<img src='assets/images/size.png' alt='icon' />
								<p
									className={`text-16 "text-black-500 font-medium ml-2`}>
									{product?.weight ?? (
										<>{ekkLanguage.createProduct.sizeDetermine} </>
									)}
								</p>
							</div>
							<div>
								<Add color='#F94B41' />
							</div>
						</div>
					</div>
					<div
						className={`bg-white p-2 block rounded-[5px] px-4 py-[14px] mb-5`}
						onClick={onOpenInventoryModal}>
						<div className='flex justify-between items-center'>
							<div className='flex items-center'>
								<img src='assets/images/warehouse2.png' alt='icon' />
								<p
									className={`text-16 "text-black-500 font-medium ml-2`}>
									{product?.initInventory ?? (
										<>
											{ekkLanguage.createProduct.inventoryDetermine}
										</>
									)}
								</p>
							</div>
							<div>
								<Add color='#F94B41' />
							</div>
						</div>
					</div>

					{/* manufacture date */}
					<div>
						<DatePicker
							showYearDropdown
							showMonthDropdown
							// minDate={moment().toDate()}
							selected={
								product?.manufactureDate
									? moment(product?.manufactureDate)._d
									: moment(new Date())._d
							}
							onChange={(date) =>
								dispatch(
									productOfferCreate({
										...product,
										manufactureDate:
											moment(date).format("YYYY-MM-DD"),
									})
								)
							}
							customInput={<OfferStartDate />}
							withPortal
						/>
					</div>

					{/* Expire date */}
					<div>
						<DatePicker
							showYearDropdown
							showMonthDropdown
							// minDate={moment().toDate()}
							selected={
								product?.expireDate
									? moment(product?.expireDate)._d
									: moment(new Date())._d
							}
							onChange={(date) =>
								dispatch(
									productOfferCreate({
										...product,
										expireDate: moment(date).format("YYYY-MM-DD"),
									})
								)
							}
							customInput={<OfferEndDate />}
							withPortal
						/>
					</div>
				</div>
				<TextInput
					type='text'
					title={ekkLanguage.createProduct.englishName}
					inputText={searchName}
					setInputText={setSearchName}
					placeholder={ekkLanguage.createProduct.englishNamePlaceholder}
				/>

				<div className='mb-12 flex justify-center pt-24'>
					<IonButton
						onClick={() => {
							if (!product?.productItems?.length) {
								onOpenSelectModal();
							} else {
								onOpenModalSaveProduct();
							}
						}}
						style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)" }}
						expand='block'
						className='text-black-500 rounded-[5px] h-12 font-sans font-bold text-20 border-2 border-black-500'>
						{ekkLanguage.createProduct.save}
					</IonButton>
					<IonButton
						// disabled={
						// 	productName && sellingPrice && initInventory && true
						// }
						disabled={publishDisabled}
						onClick={() => {
							if (!product?.productItems?.length) {
								onOpenSelectModal();
							} else {
								onOpenModal();
							}
						}}
						style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)" }}
						expand='block'
						className='bg-success rounded-[5px] h-12 font-sans font-bold text-20'>
						{ekkLanguage.createProduct.publish}
					</IonButton>
				</div>
			</div>

			{/* select conformation modal*/}
			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={selectProductModal}
				onRequestClose={onCloseSelectModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-4'>
						{ekkLanguage.createOffer.spWarnings}
					</h6>

					<div className='flex justify-end'>
						<button
							onClick={() => onCloseSelectModal()}
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-000080 text-white flex justify-center items-center`}>
							{ekkLanguage.createOffer.closeButton}
						</button>
					</div>
				</div>
			</Modal>

			{/* order publish conformation modal*/}
			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createOffer.conformModal}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							onClick={() => createProductPublishedHandler("Publish")}
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-[#0c921c] text-white flex justify-center items-center`}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>

			{/* order save conformation modal*/}
			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpenSaveProduct}
				onRequestClose={onCloseModalSaveProduct}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createOffer.saveConformModal}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModalSaveProduct}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							onClick={() => createProductPublishedHandler("Unpublish")}
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white flex justify-center items-center`}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>

			{/* set Price modal*/}
			<Modal
				style={customModalStyles2}
				closeTimeoutMS={200}
				isOpen={isOpenPrice}
				onRequestClose={onCloseSelectModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<div className='border-b-2 mb-4 flex  justify-between'>
						<h4 className='text-14 text-222222 font-bold pb-[10px]'>
							{ekkLanguage.createProduct.setPrice}
						</h4>
						<span onClick={onClosePriceModal} className='cursor-pointer'>
							<Cross />
						</span>
					</div>
					<div>
						<TextInputWithIcon
							type='number'
							title={ekkLanguage.createProduct.purchasePrice}
							inputText={purchasePrice}
							setInputText={setPurchasePrice}
							placeholder={
								ekkLanguage.createProduct.purchasePricePlaceHolder
							}
							icon='assets/images/cash.png'
						/>
						<TextInputWithIcon
							type='number'
							title={ekkLanguage.createProduct.sellingPrice}
							inputText={sellingPrice}
							setInputText={setSellingPrice}
							placeholder={
								ekkLanguage.createProduct.sellingPricePlaceHolder
							}
							icon='assets/images/shop.png'
							errorText={sellingPriceEmpty}
							required={true}
						/>
						<TextInputWithIcon
							type='number'
							title={ekkLanguage.createProduct.customerPrice}
							inputText={customerPrice}
							setInputText={setCustomerPrice}
							placeholder={
								ekkLanguage.createProduct.customerPricePlaceHolder
							}
							icon='assets/images/user.png'
						/>
						<TextInputWithIcon
							type='number'
							title={ekkLanguage.createProduct.taxVat + "(%)"}
							inputText={taxVat}
							setInputText={setTaxVat}
							placeholder={ekkLanguage.createProduct.taxVatPlaceHolder}
							icon='assets/images/vat.png'
						/>
						<TextInputWithIcon
							type='number'
							title={ekkLanguage.createProduct.moq}
							inputText={moq}
							setInputText={setMoq}
							placeholder={ekkLanguage.createProduct.moqPlaceHolder}
							icon='assets/images/package.png'
						/>
					</div>
					<div className='flex'>
						<button
							onClick={onClosePriceModal}
							color='primary'
							className={`font-bold h-10 rounded-[5px] w-full ml-2 bg-success text-white flex justify-center items-center`}>
							{ekkLanguage.createProduct.ok}
						</button>
					</div>
				</div>
			</Modal>

			{/* set size modal*/}
			<Modal
				style={customModalStyles2}
				closeTimeoutMS={200}
				isOpen={isOpenSize}
				onRequestClose={onCloseSizeModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<div className='border-b-2 mb-4 flex  justify-between'>
						<h4 className='text-14 text-222222 font-bold pb-[10px]'>
							{ekkLanguage.createProduct.sizeDetermine}
						</h4>
						<span onClick={onCloseSizeModal} className='cursor-pointer'>
							<Cross />
						</span>
					</div>
					<div>
						<div>
							<IonRow>
								<IonCol
									size='6'
									style={{ padding: 0, paddingRight: 5 }}>
									<TextInputWithIcon
										type='number'
										title={ekkLanguage.createProduct.weight}
										inputText={weight}
										setInputText={setWeight}
										placeholder={
											ekkLanguage.createProduct.weightPlaceholder
										}
										icon='assets/images/scale.png'
										errorText={
											!product?.weight &&
											ekkLanguage.createProduct.weightWarning
										}
									/>
								</IonCol>

								<IonCol size='6' style={{ padding: 0, paddingLeft: 5 }}>
									<SelectWithIcon
										title={ekkLanguage.createProduct.weightUnits}
										selectItem={measurement}
										setSelectItem={setMeasurement}
										options={measurementOptions}
										placeholder={
											ekkLanguage.createProduct.measurement
										}
										icon='assets/images/weight.png'
									/>
								</IonCol>
								<IonCol size='12'>
									<SelectWithIcon
										title={ekkLanguage.createProduct.packingType}
										selectItem={packagingType}
										setSelectItem={setPackagingType}
										options={packingOptions}
										placeholder={
											ekkLanguage.createProduct.measurement
										}
										icon='assets/images/trolley-cart.png'
									/>
								</IonCol>
							</IonRow>
						</div>
					</div>
					<div className='pb-5 relative'>
						{/* <p
							className='text-black-200 bg-white absolute'
							style={{
								left: "50%",
								transform: "translate(-50%, -50%)",
								padding: "0 20px",
							}}>
							or
						</p> */}
						<hr />
					</div>
					<div>
						<IonRow>
							<IonCol size='6' style={{ padding: 0, paddingRight: 5 }}>
								<TextInputWithIcon
									type='number'
									title={ekkLanguage.createProduct.width}
									inputText={width}
									setInputText={setWidth}
									placeholder={
										ekkLanguage.createProduct.widthPlaceholder
									}
									icon='assets/images/width.png'
								/>
							</IonCol>
							<IonCol size='6' style={{ padding: 0, paddingLeft: 5 }}>
								<TextInputWithIcon
									type='number'
									title={ekkLanguage.createProduct.height}
									inputText={height}
									setInputText={setHeight}
									placeholder={
										ekkLanguage.createProduct.heightPlaceholder
									}
									icon='assets/images/height.png'
								/>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size='6' style={{ padding: 0, paddingRight: 5 }}>
								<TextInputWithIcon
									type='number'
									title={ekkLanguage.createProduct.length}
									inputText={length}
									setInputText={setLength}
									placeholder={
										ekkLanguage.createProduct.lengthPlaceholder
									}
									icon='assets/images/resize.png'
								/>
							</IonCol>
							<IonCol size='6' style={{ padding: 0, paddingLeft: 5 }}>
								<SelectWithIcon
									title={ekkLanguage.createProduct.units}
									selectItem={measurementUnit}
									setSelectItem={setMeasurementUnit}
									options={unitOptions}
									placeholder={ekkLanguage.createProduct.measurement}
									icon='assets/images/scall.png'
								/>
							</IonCol>
						</IonRow>
					</div>
					<hr />
					<div>
						<h6 className='font-bold pt-3 pb-3'>
							{ekkLanguage.createProduct.finalGrossWeight}
						</h6>
						<IonRow>
							<IonCol size='12' style={{ padding: 0, paddingLeft: 5 }}>
								<TextInputWithIcon
									type='number'
									title={ekkLanguage.createProduct.grossWeight}
									inputText={grossWeight}
									setInputText={setGrossWeight}
									placeholder={
										ekkLanguage.createProduct.grossWeightPlaceholder
									}
									icon='assets/images/scale.png'
								/>
							</IonCol>
							<IonCol size='12'>
								<SelectWithIcon
									title={ekkLanguage.createProduct.grossWeightUnit}
									selectItem={grossWeightUnit}
									setSelectItem={setGrossWeightUnit}
									options={grossUnitOptions}
									placeholder={ekkLanguage.createProduct.measurement}
									icon='assets/images/weight.png'
								/>
							</IonCol>
						</IonRow>
					</div>
					<div className='flex'>
						<button
							onClick={() => {
								onCloseSizeModal();
							}}
							color='primary'
							className={`font-bold h-10 rounded-[5px] w-full ml-2 bg-success text-white flex justify-center items-center`}>
							{ekkLanguage.createProduct.ok}
						</button>
					</div>
				</div>
			</Modal>

			{/* set Manufacturer modal*/}
			<Modal
				style={customModalStyles2}
				closeTimeoutMS={200}
				isOpen={isOpenManufacturer}
				onRequestClose={onCloseManufacturerModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<div className='border-b-2 mb-4 flex  justify-between'>
						<h4 className='text-14 text-222222 font-bold pb-[10px]'>
							{ekkLanguage.createProduct.ManufacturerInformation}
						</h4>
						<span
							onClick={onCloseManufacturerModal}
							className='cursor-pointer'>
							<Cross />
						</span>
					</div>
					<div>
						<div className='mb-5'>
							<div className='flex'>
								<img
									src='assets/images/europe.png'
									alt='icon'
									className='mr-[10px] w-5 h-5'
								/>

								<h4 className='pb-3 font-bold text-14'>
									{ekkLanguage.createProduct.manufacturedCountry}
								</h4>
							</div>
							<Select
								styles={customStyleSelect}
								className='basic-single w-full'
								placeholder='+88'
								name='color'
								options={countryList}
								onChange={(e) => handleSelectCountry(e)}
								components={{
									IndicatorSeparator: () => null,
								}}
								style={{ fontSize: 14 }}
								defaultValue={{
									countryCode: "+88",
									country: "Bangladesh",
									value: "+88",
									label: (
										<div className='flex items-center'>
											<img
												src={userCountry.flag}
												className='h-auto w-12 rounded-[5px]'
												alt='flag'></img>
											<p className='text-18 pl-2 font-bold'>
												{/* {ekkLanguage.createNewBrand.selectCountry} */}
												{country}
											</p>
										</div>
									),
								}}
							/>
						</div>
						<TextInputWithIcon
							type='text'
							title={ekkLanguage.createProduct.manufacturedBy}
							inputText={manufacturer}
							setInputText={setManufacturer}
							placeholder={
								ekkLanguage.createProduct.manufacturedByPlaceHolder
							}
							icon='assets/images/manufacturer2.png'
						/>
						<TextInputWithIcon
							type='text'
							title={ekkLanguage.createProduct.importedBy}
							inputText={importer}
							setInputText={setImporter}
							placeholder={
								ekkLanguage.createProduct.importedByPlaceHolder
							}
							icon='assets/images/plane.png'
						/>
						<TextInputWithIcon
							type='text'
							title={ekkLanguage.createProduct.distributedBy}
							inputText={distributer}
							setInputText={setDistributer}
							placeholder={
								ekkLanguage.createProduct.distributedByPlaceHolder
							}
							icon='assets/images/moving-truck.png'
						/>
					</div>

					<div className='flex'>
						<button
							onClick={onCloseManufacturerModal}
							color='primary'
							className={`font-bold h-10 rounded-[5px] w-full ml-2 bg-success text-white flex justify-center items-center`}>
							{ekkLanguage.createProduct.ok}
						</button>
					</div>
				</div>
			</Modal>

			{/* set Inventory modal*/}
			<Modal
				style={customModalStyles2}
				closeTimeoutMS={200}
				isOpen={isOpenInventory}
				onRequestClose={onCloseInventoryModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<div className='border-b-2 mb-4 flex  justify-between'>
						<h4 className='text-14 text-222222 font-bold pb-[10px]'>
							{ekkLanguage.createProduct.inventoryDetermine}
						</h4>
						<span
							onClick={onCloseInventoryModal}
							className='cursor-pointer'>
							<Cross />
						</span>
					</div>
					<div>
						<TextInputWithIcon
							type='number'
							title={ekkLanguage.createProduct.initialInventory}
							inputText={initInventory}
							setInputText={setInitInventory}
							placeholder={
								ekkLanguage.createProduct.initialInventoryPlaceHolder
							}
							icon='assets/images/warehouse.png'
						/>
						<TextInputWithIcon
							type='number'
							title={ekkLanguage.createProduct.sku}
							inputText={sku}
							setInputText={setSku}
							placeholder={ekkLanguage.createProduct.skuPlaceholder}
							icon='assets/images/tracking-number.png'
						/>
						<div>
							<div className='flex'>
								<img
									src='assets/images/barcode-scan.png'
									alt='icon'
									className='mr-[10px] w-5 h-5'
								/>

								<h4 className='pb-3 font-bold text-14'>
									{ekkLanguage.createProduct.barCode}
								</h4>
							</div>
							<div className='p-5 border border-black-500 rounded-[5px] bg-F8F8F8 text-center m-auto mb-5'>
								<img
									src='assets/images/barcode-scanner.png'
									alt='icon'
									className='mb-5 m-auto'
								/>
								<button
									className='py-[8px] px-10 text-18 font-bold rounded-[5px]'
									style={{ background: "#BFBFBF" }}>
									{ekkLanguage.createProduct.scan}
								</button>
							</div>
						</div>
					</div>

					<div className='flex'>
						<button
							onClick={onCloseInventoryModal}
							color='primary'
							className={`font-bold h-10 rounded-[5px] w-full ml-2 bg-success text-white flex justify-center items-center`}>
							{ekkLanguage.createProduct.ok}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
