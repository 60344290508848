import { IonItem } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import { setProductDetails } from "redux/container/userSlice";
import { useNavigate } from "react-router-dom";
import Product from "services/Product";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { productPackCreate } from "redux/container/productSlice";
import TextInputWithIcon from "../Common/TextInputWithIcon";
import Cross from "assets/images/icon/svg/Cross";

export default function ProductDetailsSheet({
	product,
	productData,
	setProduct,
}) {
	let pack = useSelector((state) => state.product.productPack);
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.productDetails);
	const [initInventory, setInitInventory] = useState(0);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isOpen, setIsOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const [isOpenUnPublish, setIsOpenUnPublish] = useState(false);
	const onOpenUnPublishModal = () => setIsOpenUnPublish(true);
	const onCloseUnPublishModal = () => setIsOpenUnPublish(false);

	const onOpenModal = () => {
		if (product.base_price && product.inventory_quantity) {
			dispatch(setProductDetails(false));
			if (product.type === "offer") {
				if (product.publish_status === "Publish") {
					onOpenUnPublishModal();
				} else {
					setIsOpen(true);
				}
			} else {
				if (product.status === "Publish") {
					onOpenUnPublishModal();
				} else {
					setIsOpen(true);
				}
			}
		} else {
			if (product.type === "offer") {
				if (product.publish_status === "Publish") {
					onOpenUnPublishModal();
					dispatch(setProductDetails(false));
				} else {
					onOpenModalPublish();
					dispatch(setProductDetails(false));
				}
			} else {
				if (product.status === "Publish") {
					onOpenUnPublishModal();
					dispatch(setProductDetails(false));
				} else {
					onOpenModalPublish();
					dispatch(setProductDetails(false));
				}
			}
		}
	};
	const onCloseModal = () => setIsOpen(false);

	const [isOpenDelete, setIsOpenDelete] = useState(false);

	const onOpenModalDelete = () => {
		dispatch(setProductDetails(false));
		setIsOpenDelete(true);
	};
	const onCloseModalDelete = () => setIsOpenDelete(false);

	const [isOpenInventory, setIsOpenInventory] = useState(false);
	const onOpenInventoryModal = () => setIsOpenInventory(true);
	const onCloseInventoryModal = () => setIsOpenInventory(false);

	const deleteHandler = async () => {
		let response =
			product.type === "offer"
				? await Product.deleteOffer(product.id)
				: await Product.deleteProduct(product.cat_id);
		if (response.status === 200) {
			onCloseModalDelete();
			navigate("/product-list");
		}
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const [isOpenPublish, setIsOpenPublish] = useState(false);
	const onOpenModalPublish = () => setIsOpenPublish(true);
	const onCloseModalPublish = () => setIsOpenPublish(false);
	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
			// height: "calc(100% - 60px)",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const statusChange = async () => {
		setLoader(true);
		let productInfo = {
			product: {
				product_id: product.product_id,
			},
			catalog: {
				cat_id: product.cat_id,
				catalog_status:
					product.status === "Publish" ? "Unpublish" : "Publish",
			},
		};
		let offerInfo = {
			offers: {
				id: product.id,
				publish_status:
					product.publish_status === "Publish" ? "Unpublish" : "Publish",
			},
		};

		let res =
			product.type === "offer"
				? await Product.updateOffer(offerInfo)
				: await Product.updateProduct(productInfo);
		if (res.status === 200) {
			setLoader(false);
			productData();
			onCloseModal();
			onCloseUnPublishModal();
		} else {
			setLoader(false);
		}
	};
	useEffect(() => {
		setInitInventory(product?.inventory_quantity);
	}, [product]);

	const inventoryUpdateHandler = async () => {
		setLoader(true);
		let productInfo = {
			product: {
				product_id: product.product_id,
			},
			catalog: {
				inventory_id: product.inventory_id,
				inventory_quantity: initInventory,
				cat_id: product?.cat_id,
			},
		};
		let offerInfo = {
			offers: {
				id: product?.id,
				inventory_quantity: initInventory,
				inventory_id: product.inventory_id,
			},
		};
		let response =
			product.type === "offer"
				? await Product.updateOffer(offerInfo)
				: await Product.updateProduct(productInfo);
		if (response.status === 200) {
			setLoader(false);
			onCloseInventoryModal();
			let updateProduct = { ...product, inventory_quantity: initInventory };
			setProduct(updateProduct);
		} else {
			setLoader(false);
		}
	};
	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[320, 320, 100, 0]}
				onClose={() => dispatch(setProductDetails(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								onOpenInventoryModal();
								dispatch(setProductDetails(false));
							}}
							lines='none'>
							<span className='py-[5px]'>
								<img
									className='h-10 w-10'
									src='/assets/images/warehouse3.png'
									alt='driver'
								/>
							</span>
							<p className='text-14 font-bold text-black-1000 pl-2'>
								{ekkLanguage.productDetails.inventory}
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								onOpenModal();
							}}
							lines='none'>
							<span className='py-[5px]'>
								{product.type === "offer" ? (
									<img
										className='h-10 w-10'
										src={
											product.publish_status !== "Publish"
												? "/assets/images/publish.png"
												: "/assets/images/unpublished.png"
										}
										alt='driver'
									/>
								) : (
									<img
										className='h-10 w-10'
										src={
											product.status !== "Publish"
												? "/assets/images/publish.png"
												: "/assets/images/unpublished.png"
										}
										alt='driver'
									/>
								)}
							</span>
							{product.type === "offer" ? (
								<p className='text-14 font-bold text-black-1000 pl-2'>
									{product.publish_status !== "Publish"
										? ekkLanguage.productDetails.published
										: ekkLanguage.productDetails.unpublished}
								</p>
							) : (
								<p className='text-14 font-bold text-black-1000 pl-2'>
									{product.status !== "Publish"
										? ekkLanguage.productDetails.published
										: ekkLanguage.productDetails.unpublished}
								</p>
							)}
						</IonItem>
						<IonItem
							button
							onClick={() => {
								dispatch(setProductDetails(false));
								navigate(
									product.type !== "offer"
										? `/update-product/${product.id}`
										: `/update-offer/${product.id}`
								);
							}}
							lines='none'>
							<span className='py-[5px]'>
								<img
									className='h-10 w-10'
									src='/assets/images/edit.png'
									alt='driver'
								/>
							</span>
							<p className='text-14 font-bold text-black-1000 pl-2'>
								{ekkLanguage.productDetails.edit}
							</p>
						</IonItem>
						{product?.type === "variant" && (
							<IonItem
								button
								onClick={() => {
									dispatch(setProductDetails(false));
									dispatch(
										productPackCreate({
											...pack,
											productItem: product,
										})
									);
									navigate("/create-pack");
								}}
								lines='none'>
								<div className='flex items-center'>
									<span className='py-[5px]'>
										<img
											className='h-10 w-10'
											src='/assets/images/products.png'
											alt='user'
										/>
									</span>
									<p className='text-16 font-bold text-black-1000 pl-2'>
										{ekkLanguage.productList.createPack}
									</p>
								</div>
							</IonItem>
						)}

						<IonItem button onClick={onOpenModalDelete} lines='none'>
							<span className='py-[5px]'>
								<img
									className='h-10 w-10'
									src='/assets/images/delete2.png'
									alt='driver'
								/>
							</span>
							<p className='text-14 font-bold text-black-1000 pl-2'>
								{ekkLanguage.productDetails.delete}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setProductDetails(false))}
				/>
			</Sheet>

			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpenDelete}
				onRequestClose={onCloseModalDelete}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<img src='/assets/images/delete.png' alt='icon' />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.productDetails.deleteText}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModalDelete}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white flex justify-center items-center`}
							onClick={!loader && deleteHandler}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>

			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createProduct.publishMessage}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							onClick={() => statusChange()}
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-[#0c921c] text-white flex justify-center items-center`}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>

			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpenUnPublish}
				onRequestClose={onCloseUnPublishModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createProduct.unPublishMessage}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseUnPublishModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							onClick={() => statusChange()}
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-[#0c921c] text-white flex justify-center items-center`}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>

			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpenPublish}
				onRequestClose={onCloseModalPublish}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createProduct.updateMessage}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModalPublish}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							onClick={() => navigate(`/update-product/${product.id}`)}
							color='primary'
							className={` font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-[#0c921c] text-white flex justify-center items-center`}>
							{ekkLanguage.createProduct.updateBtn}
						</button>
					</div>
				</div>
			</Modal>

			{/* set Inventory modal*/}
			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpenInventory}
				onRequestClose={onCloseInventoryModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<div className='border-b-2 mb-4 flex  justify-between'>
						<h4 className='text-14 text-222222 font-bold pb-[10px]'>
							{ekkLanguage.createProduct.inventoryDetermine}
						</h4>
						<span
							onClick={onCloseInventoryModal}
							className='cursor-pointer'>
							<Cross />
						</span>
					</div>
					<div>
						<TextInputWithIcon
							type='number'
							title={ekkLanguage.createProduct.initialInventory}
							inputText={initInventory}
							setInputText={setInitInventory}
							placeholder={
								ekkLanguage.createProduct.initialInventoryPlaceHolder
							}
							icon='/assets/images/warehouse.png'
						/>
					</div>

					<div className='flex'>
						<button
							onClick={inventoryUpdateHandler}
							color='primary'
							className={`font-bold h-10 rounded-[5px] w-full ml-2 bg-success text-white flex justify-center items-center`}>
							{ekkLanguage.createProduct.updateBtn}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
