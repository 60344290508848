import React, { useState, useEffect } from "react";
import DownloadIcon from "assets/images/icon/svg/DownloadIcon";
import { useSelector } from "react-redux";
import Modal from "react-modal";

export default function DownloadPwaScreen() {
	const [isOpen, setIsOpen] = useState(true);
	const [supportsPWA, setSupportsPWA] = useState(false);
	const [promptInstall, setPromptInstall] = useState(null);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	useEffect(() => {
		const handler = (e) => {
			e.preventDefault();
			console.log("we are being triggered :D");
			setSupportsPWA(true);
			setIsOpen(true);
			setPromptInstall(e);
		};
		window.addEventListener("beforeinstallprompt", handler);
		return () => window.removeEventListener("transitionend", handler);
	}, []);

	const onClick = (evt) => {
		evt.preventDefault();
		if (!promptInstall) {
			return;
		}
		promptInstall.prompt();
		setIsOpen(false);
	};

	if (!supportsPWA) {
		return null;
	}
	const onCloseModal = () => {
		setIsOpen(false);
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	return (
		<>
			<Modal
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				id='pwa-modal'
				style={customStyles}>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<DownloadIcon />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.pwaDownload.header}
					</h6>
					<p className='text-12 font-medium pb-[10px] text-black-500'>
						{ekkLanguage.pwaDownload.paragraph}
					</p>
					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid text-14'
							onClick={onCloseModal}>
							{ekkLanguage.pwaDownload.cancelBtn}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-[#0c921c] text-white text-14'
							onClick={onClick}>
							{ekkLanguage.pwaDownload.downloadBtn}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}
