import { IonButton, IonLabel, IonSpinner } from "@ionic/react";
import CustomSelect from "components/global-components/common/CustomSelect";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { InputWithImage } from "components/global-components/common/InputWithImage";
import { getLanguage } from "config/language";
import { COUNTRYINFO } from "data/country";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import Modal from "react-modal";
import {
	setAccountName,
	setAccountNo,
	setAddress,
	setBranch,
	setCity,
	setCountry,
} from "redux/container/addBankSlice";
import Payment from "services/Payment";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { setEmptyBankData } from "redux/container/addBankSlice";
import { getUserBusiness } from "config/user";

export default function AddBankAccount({ businessData, ekkLanguage }) {
	let id = localStorage.getItem("_b");
	let { pathname } = useLocation();
	const parseId = JSON.parse(id);
	const businessId = parseId.id;
	const bankData = useSelector((state) => state.bankSettings.bankData);
	const accountName = useSelector((state) => state.bankSettings.accountName);
	const accountNo = useSelector((state) => state.bankSettings.accountNo);
	const branch = useSelector((state) => state.bankSettings.branch);
	const address = useSelector((state) => state.bankSettings.address);
	const country = useSelector((state) => state.user.data.country);
	const city = useSelector((state) => state.bankSettings.city);
	const [selectedCountry, setSelectedCountry] = useState("Bangladesh");
	// const [accountName, setAccountName] = useState("");
	const [accountNumber, setAccountNumber] = useState("");
	// const [bankName, setBankName] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isWarnings, setIsWarnings] = useState(false);
	const [warningText, setWarningText] = useState("");
	const openWarning = () => setIsWarnings(true);
	const [loader, setLoader] = useState(false);
	let [isPaymentTerm, setIsPaymentTerm] = useState(false);
	const closeWarning = () => {
		setIsWarnings(false);
		setWarningText("");
	};
	let business = JSON.parse(localStorage.getItem("setUser"));
	const countryHandler = (e) => {
		dispatch(setCountry(e.country));
		// setSelectedCountry(e.country);
	};

	var countryList = Object.values(COUNTRYINFO).map((val) => ({
		countryCode: val.code,
		country: val.name,
		value: val.code,
		flag: val.flag,
	}));
	const selectedOption = countryList.find((el) => el.country === country);
	const customStyles = {
		control: (provided) => ({
			...provided,
			border: "none",
			height: 48,
			paddingLeft: 7,
		}),
	};
	const CustomSingleValue = ({ children, ...props }) => (
		<components.SingleValue {...props}>
			<div className='flex items-center w-[113px]'>
				<img
					src={selectedOption.flag}
					className='sm:h-[20px] sm:w-[30px] h-5 w-[33px] rounded-[2px]'
					alt='flag'
					style={{ marginRight: "10px" }}
				/>

				{selectedOption.country}
			</div>
		</components.SingleValue>
	);

	const CustomOption = ({ innerProps, label, data }) => (
		<div
			className='flex items-center py-1 pl-[15px] cursor-pointer'
			{...innerProps}>
			<img
				src={data.flag}
				className='sm:h-[20px] sm:w-[30px] h-5 w-[33px] rounded-[2px]'
				alt='flag'
				style={{ marginRight: "10px" }}
			/>
			{data.country}
		</div>
	);
	const DropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<img
					className='w-[18px] h-[18px] mr-1'
					src='/assets/images/dropdown.png'
					alt=''
				/>
			</components.DropdownIndicator>
		);
	};

	const handleCitySelection = () => {};

	const handleAddBankAccount = () => {
		if (!accountName) {
			setWarningText(ekkLanguage.addBankSettings.bankNameWarning);
			openWarning();
			return;
		}
		if (!accountNo) {
			setWarningText(ekkLanguage.addBankSettings.bankNumberWarning);
			openWarning();
			return;
		}
		if (!bankData?.name) {
			setWarningText(ekkLanguage.addBankSettings.selectBankWarning);
			openWarning();
			return;
		}
		if (!branch) {
			setWarningText(ekkLanguage.addBankSettings.branchWarning);
			openWarning();
			return;
		}
		if (!address) {
			setWarningText(ekkLanguage.addBankSettings.addressWarning);
			openWarning();
			return;
		}
		if (!bankData?.name) {
			setWarningText(ekkLanguage.addBankSettings.cityWarning);
			openWarning();
			return;
		}
		const data = {
			business_id: businessId,
			account_name: accountName,
			account_no: accountNo,
			branch: branch,
			bank: bankData.bankId,
			country: country,
			address: address,
			city: city,
		};
		setLoader(true);
		Payment.createBankAccount(data)
			.then((res) => {
				setLoader(false);
				toast.success("Successfully added bank account");
				dispatch(setEmptyBankData());
				handleNavigateBankList();
			})
			.catch((error) => {
				setLoader(false);
				toast.error("Error adding bank account");
				console.error("Error adding bank account:", error);
			});
	};

	let getAllPaymentInfo = async function () {
		let results = await Payment.getAllPaymentTerms();
		let paymentTerms = await Payment.sellerPaymentTerms();
		if (results?.status === 200) {
			let ids = [];
			results?.results.map((item1) => {
				let matchChecker = paymentTerms.results.find(
					(item2) => item2.id === item1.id
				);
				if (matchChecker) {
					ids.push(item1.id);
					return { ...item1, is_active: true };
				} else {
					return { ...item1, is_active: false };
				}
			});
			setIsPaymentTerm(ids.length > 1);
		}
	};
	useEffect(() => {
		getAllPaymentInfo();
	}, []);
	const handleNavigateBankList = () => {
		if (!isPaymentTerm) {
			navigate(`/init-payment-terms-settings`);
		} else {
			navigate("/business-success");
		}
	};

	const modalCustomStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	return (
		<div className='pt-[70px] px-4'>
			{/* Select Bank  */}
			<IonLabel className='flex items-center mb-[11px] '>
				<img
					className='mr-[10px] w-6 h-6'
					src='/assets/images/selectBank.png'
					alt=''></img>
				<span className='text-16 font-bold'>
					{ekkLanguage.addBankSettings.selectBank}
				</span>
				<span className='text-primary ml-1'>*</span>
			</IonLabel>
			<div
				className={`bg-white flex px-[15px] mb-5 h-12 w-full rounded border  `}>
				{bankData?.name ? (
					<div
						onClick={() => navigate("/bank-list/default-bank")}
						className='text-center flex text-black-1000 text-14 font-semibold items-center justify-between w-full '>
						<span className='mr-2 '>{bankData?.name}</span>
						<img src='/assets/images/rightArrow.png' alt='' />
					</div>
				) : (
					<div
						onClick={() => navigate("/bank-list/default-bank")}
						className='text-center flex  text-14  text-[#00000054] items-center justify-between w-full'>
						<span className='mr-2 '>
							{ekkLanguage.addBankSettings.selectBankPlaceHolder}
						</span>{" "}
						<img src='/assets/images/rightArrow.png' alt='' />
					</div>
				)}
			</div>

			{/* Account Name  */}
			<InputWithImage
				star={true}
				label={{
					label: ekkLanguage.addBankSettings.accountName,
					source: "/assets/images/accountName.png",
				}}
				onChange={(event) => {
					dispatch(setAccountName(event.target.value));
				}}
				//   extraLabel="Extra label"
				type='text'
				name='load'
				value={accountName}
				placeholder={
					ekkLanguage.addBankSettings.accountNamePlaceholder
				}></InputWithImage>

			{/* Account Number  */}
			<InputWithImage
				star={true}
				label={{
					label: ekkLanguage.addBankSettings.accountNumber,
					source: "/assets/images/accountNumber.png",
				}}
				onChange={(event) => {
					dispatch(setAccountNo(event.target.value));
				}}
				//   extraLabel="Extra label"
				type='number'
				name='load'
				value={accountNo}
				placeholder={
					ekkLanguage.addBankSettings.accountNumberPlaceholder
				}></InputWithImage>

			{/* Enter Branch  */}
			<InputWithImage
				star={true}
				label={{
					label: ekkLanguage.addBankSettings.branch,
					source: "/assets/images/branchName.png",
				}}
				onChange={(event) => {
					dispatch(setBranch(event.target.value));
				}}
				//   extraLabel="Extra label"
				type='text'
				name='load'
				value={branch}
				placeholder={
					ekkLanguage.addBankSettings.branchPlaceHolder
				}></InputWithImage>

			{/* Address  */}
			<InputWithImage
				star={true}
				label={{
					label: ekkLanguage.addBankSettings.address,
					source: "/assets/images/address.png",
				}}
				onChange={(event) => {
					dispatch(setAddress(event.target.value));
				}}
				//   extraLabel="Extra label"
				type='text'
				name='load'
				value={address}
				placeholder={
					ekkLanguage.addBankSettings.addressPlaceholder
				}></InputWithImage>

			{/* Select Country */}
			{/* <IonLabel className='flex items-center mb-[11px] '>
				<img
					className='mr-[10px] w-6 h-6'
					src='/assets/images/selectCountry.png'
					alt=''></img>
				<span className='text-16 font-bold'>
					{ekkLanguage.addBankSettings.selectCountry}
				</span>
			</IonLabel> */}
			{/* <Select
				className='mb-5'
				styles={customStyles}
				options={countryList}
				isSearchable={false}
				defaultValue={selectedOption}
				onChange={(e) => countryHandler(e)}
				components={{
					IndicatorSeparator: () => null,
					Option: CustomOption,
					SingleValue: CustomSingleValue,
					DropdownIndicator: DropdownIndicator,
				}}
			/> */}

			{/* Select City  */}
			{/* <CustomSelect
        type="city-selection"
        // handleOption={handleCitySelection}
        localLanguage={appLanguage}
        // options={cities}
        setOption={setCity}
        option={city}
        mainLabel={ekkLanguage.addBankSettings.selectCity}
        // extraLabel={ekkLanguage.workVerify.workAreaLabel2}
        // star={true}
        labelImage="/assets/images/selectCity.png"
        selectPlaceholder={ekkLanguage.addBankSettings.selectCityPlaceHolder}
      ></CustomSelect> */}

			<InputWithImage
				star={true}
				label={{
					label: ekkLanguage.addBankSettings.selectCityPlaceHolder,
					source: "/assets/images/selectCity.png",
				}}
				onChange={(event) => {
					dispatch(setCity(event.target.value));
				}}
				//   extraLabel="Extra label"
				type='text'
				name='load'
				value={city}
				placeholder={
					ekkLanguage.addBankSettings.selectCityPlaceHolder
				}></InputWithImage>
			{/* Enter Postal Code  */}
			{/* <InputWithImage
        label={{
          label: ekkLanguage.addBankSettings.postalCode,
          source: "/assets/images/postalCode.png",
        }}
        onChange={(event) => {
          setPostalCode(event.target.value);
          console.log(event.target.value);
        }}
        //   extraLabel="Extra label"
        type="number"
        name="load"
        value={postalCode}
        placeholder={ekkLanguage.addBankSettings.postalCodePlaceHolder}
      ></InputWithImage> */}
			{/* Save Button  */}
			<div className='pt-24 text-center pb-10'>
				<IonButton
					disabled={
						accountName &&
						accountNo &&
						bankData?.name &&
						branch &&
						address
							? false
							: true
					}
					color={
						accountName &&
						accountNo &&
						bankData?.name &&
						branch &&
						address
							? "success"
							: "dark"
					}
					className='font-bold h-[50px] w-full'
					onClick={handleAddBankAccount}>
					{loader ? (
						<IonSpinner></IonSpinner>
					) : (
						<>
							{ekkLanguage.businessCreateName.btnLabel}
							<span className='pl-2'>
								<ArrayRight />
							</span>
						</>
					)}
				</IonButton>
			</div>
			<Modal
				style={modalCustomStyles}
				closeTimeoutMS={200}
				isOpen={isWarnings}
				onRequestClose={closeWarning}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[20px]'>
						{ekkLanguage.createOrder.importentMessage}
					</h6>
					<h6 className='text-16 font-semibold pb-[20px]'>
						{warningText}
					</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-[#0c921c] text-white'
							onClick={closeWarning}>
							{ekkLanguage.createOrder.okBtnLabel}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
