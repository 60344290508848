import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
} from "@ionic/react";
import { clearAuth } from "config/authFetch";
import { getUserBusiness } from "config/user";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { actions, logout } from "redux/container/authSlice";
import User from "services/User";

export default function BusinessSuccess() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const kyc = useSelector((state) => state.businessSettings.data);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const business = getUserBusiness();
	const setTrialHandler = async () => {
		let query = {
			seller_id: business.id,
			country: getUserBusiness().country,
		};
		let response = await User.setTrialNewUser(query);
		if (response.success) {
			toast.success(ekkLanguage.businessCreateName.trialStart);
		}
	};
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<IonGrid>
						<IonCol size='12'>
							<div className='flex items-center justify-center text-center h-screen px-4'>
								<div>
									<img
										src='/assets/images/winner.png'
										alt='congratulateion'
										className='inharit m-auto'
									/>
									<h2 className='text-30 text-success font-bold mb-2 pt-3'>
										{ekkLanguage.businessCreateName.congratulation}
									</h2>
									{kyc.business_status === "Pending" ? (
										<>
											<p className='text-20 text-black-500 font-semibold mb-5'>
												{ekkLanguage.businessCreateName.pending}
											</p>

											{/* <Link
												to='/nid-verification'
												className='w-full pb-10'>
												<IonButton
													className='rounded-[10px] h-[50px] w-full text-white font-bold bg-success mb-5'
													style={{
														boxShadow:
															"0px 5px 15px rgba(0, 0, 0, 0.2)",
													}}>
													{
														ekkLanguage.businessCreateName
															.kycSubmit
													}
												</IonButton>
											</Link> */}
											<button
												onClick={() => {
													localStorage.clear();
													window.location.reload();
												}}
												className='w-full'>
												<IonButton
													className='rounded-[10px] h-[50px] w-full text-white font-bold bg-[#0c921c]'
													style={{
														boxShadow:
															"0px 5px 15px rgba(0, 0, 0, 0.2)",
													}}>
													{ekkLanguage.profile.logoutMenuLable}
												</IonButton>
											</button>
										</>
									) : (
										<>
											<p className='text-20 text-black-500 font-semibold mb-5'>
												{
													ekkLanguage.businessCreateName
														.congratulationMessage
												}
											</p>
											<Link to='/dashboard' className='w-full'>
												<IonButton
													onClick={setTrialHandler}
													className='rounded-[10px] h-[50px] w-full text-white font-bold bg-success mb-5'
													style={{
														boxShadow:
															"0px 5px 15px rgba(0, 0, 0, 0.2)",
													}}>
													{
														ekkLanguage.businessCreateName
															.freeTrail
													}
												</IonButton>
											</Link>
											<Link
												to='/nid-verification'
												className='w-full'>
												<IonButton
													className='rounded-[10px] h-[50px] w-full text-white font-bold bg-success mb-5'
													style={{
														boxShadow:
															"0px 5px 15px rgba(0, 0, 0, 0.2)",
													}}>
													{
														ekkLanguage.businessCreateName
															.kycSubmit
													}
												</IonButton>
											</Link>
											<button
												onClick={() => {
													dispatch(actions.logout());
													localStorage.removeItem("master_token");
													localStorage.removeItem("_u");
													localStorage.removeItem("_b");
													localStorage.removeItem("cb");
													localStorage.setItem("auth", "false");
													clearAuth();
													navigate("/");
												}}
												className='w-full'>
												<IonButton
													className='rounded-[10px] h-[50px] w-full text-white font-bold bg-[#0c921c]'
													style={{
														boxShadow:
															"0px 5px 15px rgba(0, 0, 0, 0.2)",
													}}>
													{ekkLanguage.businessCreateName.goBack}
												</IonButton>
											</button>
										</>
									)}
								</div>
							</div>
						</IonCol>
					</IonGrid>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
